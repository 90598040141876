@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;800&display=swap");
 


.shadoweria {
    box-shadow: 13px 13px 1px #252B48;
    border: 3px solid;
  }

.-sm{
    @media screen and (min-width: 0px) {
    display: none;
    }
}
@keyframes fall {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
}

.animate-fall {
  animation: fall 1s ease-in ;
}
@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
.animate-countdown {
  animation: countUp 0.4s ease-in-out;
}

#Search::-webkit-search-cancel-button{
  display: none;
}
#Search::-webkit-search-results-button{
  display: none;
}